import React from 'react'
import './Footer.css'
import logo from '../img/logo.png'

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__container'>
        <div className='footer__logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='footer__rights'>
          <p>
            Lukon © 2018-2025 Всі права захищені. При використанні матеріалів сайту, посилання на www.lukon.com.ua
            обов'язкове.
          </p>
          <p>v. 1.8.4</p>
          {/* Перша - загальна версія, друга - фронт, третя - бек   */}
        </div>
        <div className='footer__contacts'>
          <h2>Контакти</h2>
          <p>Відділ продажу: +38 (050) 603-08-43</p>
          <p>Дирекція: +38 (050) 603-08-43</p>
          <p>Комерційні пропозиції: +38 (050) 603-08-43</p>
        </div>
        <div className='footer__grafic'>
          <h2>Графік роботи</h2>
          <p>Пн-Пт: с 9:00 до 18:00</p>
          <p>Сб: с 9:00 до 16:00</p>
          <p>Нд: Вихідний</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
