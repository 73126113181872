import axios from 'axios'

const $host = axios.create({
  // baseURL: "http://localhost:5050"
  // baseURL: "http://vs3143.mirohost.net:5000"
  baseURL: 'https://backend.lukon.com.ua' // суб домен для бекенда
})
//

export { $host }
