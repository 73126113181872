import { $host } from './index'

export const createOrder = async post => {
  try {
    const { data } = await $host.post('api/order/create', post)
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка відправлення замовлення')
  }
}

export const ordersByClientId = async post => {
  try {
    const { data } = await $host.post('/orders/by-client-id', post)
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання замовлення')
  }
}

export const getOrderById = async post => {
  try {
    const { data } = await $host.post('/order/by-id', post)
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання замовлення')
  }
}

export const changeOrderStatus = async post => {
  try {
    const { data } = await $host.post('/order/change-status', post)
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка відправлення замовлення')
  }
}

export const fetchAllOrders = async () => {
  try {
    const { data } = await $host.get('api/order/get-all')
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання списку замовлень')
  }
}
