import PriceForRetailOrWholesale from 'components/PriceForRetailOrWholesale'
import altLogo from 'img/altLogo.png'
import React from 'react'
import { useDispatch } from 'react-redux'
import { UpdateBasketQuantity } from 'redux/toolkitSlice'

const ProductItem = ({ product, actionButtons, quantity = false }) => {
  const dispatch = useDispatch()

  const handleQuantityChange = e => {
    const newQuantity = e.target.value
    if (newQuantity >= 1 || newQuantity === '') {
      dispatch(UpdateBasketQuantity({ product: product, newQuantity: newQuantity }))
    }
  }

  return (
    <div className='flex items-center justify-between flex-col w-full mt-1 p-2 rounded-md border border-solid border-gray-200 flex-wrap gap-1'>
      <div className=' w-full flex items-center justify-start gap-2'>
        <div className='block w-14 mr-1'>
          <img
            className='w-full h-full'
            src={product.img && product.img.length > 0 ? product.img[0] : altLogo}
            alt='product'
          />
        </div>
        <p className='line-clamp-2 break-words '>{product.name}</p>
      </div>
      <div className='w-full flex items-center justify-between gap-2'>
        <div className=''>
          Ціна: <PriceForRetailOrWholesale priceUSD={product.priceUSD} priceUAH={product.priceUAH} />
        </div>
        {/*quantity*/}
        {quantity && (
          <div>
            К-сть:{' '}
            <input
              className='p-2 w-20'
              type={'number'}
              min={1}
              value={product.quantity}
              onChange={handleQuantityChange}
            />
          </div>
        )}
        {quantity && (
          <div>
            Cума: <PriceForRetailOrWholesale priceUSD={product.amount.amountUSD} priceUAH={product.amount.amountUAH} />
          </div>
        )}
        <div>{actionButtons}</div>
      </div>
    </div>
  )
}

export default ProductItem
