import { CATALOG_ROUTE } from 'constants/consts'
import altLogo from 'img/altLogo.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCategoryName, setProducts } from 'redux/toolkitSlice'

const CategoryItem = ({ category }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleNavigate() {
    if (category.products.length === 0) {
      navigate(CATALOG_ROUTE + `/${category.id}`)
      dispatch(setProducts([]))
    } else {
      // навігація на сторінку продуктів
      navigate(CATALOG_ROUTE + `/${category.id}` + '/products')
      dispatch(setCategoryName(category.name))
      dispatch(setProducts(category.products))
    }
  }

  return (
    <div
      className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 min-w-min cursor-pointer p-2 rounded-md hover:bg-gray-200 flex justify-center items-center flex-col'
      onClick={handleNavigate}
    >
      <div className='flex flex-col h-full justify-between'>
        <div className='flex-grow flex items-center justify-center'>
          <div className='block w-80 overflow-hidden'>
            <img
              className='w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-105'
              src={category.img || altLogo}
              alt=''
            />
          </div>
        </div>
        <div className='break-words mt-2 text-center'>{category.name}</div>
      </div>
    </div>
  )
}

export default CategoryItem
