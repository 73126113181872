import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'

const ButtonBack = () => {
  const handleGoBack = () => {
    // Логіка для повернення назад
    window.history.back()
  }

  return (
    <div className='mt-2 ml-2'>
      <ButtonActionModal title={'< Назад'} action={handleGoBack} />
    </div>
  )
}

export default ButtonBack
