import { DOLLAR_EXCHANGE_RATE } from 'constants/consts'
import { useSelector } from 'react-redux'

const PriceForRetailOrWholesale = ({ priceUSD, priceUAH }) => {
  const { user } = useSelector(state => state.toolkit)

  return user && user.opt
    ? priceUSD
      ? priceUSD?.toFixed(2) + '$'
      : priceUAH?.toFixed(2) + 'грн'
    : priceUSD
      ? Math.ceil(priceUSD * DOLLAR_EXCHANGE_RATE * 1.3) + 'грн'
      : Math.ceil(priceUAH * 1.3) + 'грн'
}

export default PriceForRetailOrWholesale
