import { CATALOG_ROUTE } from 'constants/consts'
import altLogo from 'img/altLogo.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCategoryName, setProducts } from 'redux/toolkitSlice'

const CategoriesItem = ({ item, handleOpenSubMenu, handleCloseSubMenu, setMenuActive }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleClickOnCategories() {
    if (item.products.length > 0) {
      dispatch(setCategoryName(item.name))
      dispatch(setProducts(item.products))
      navigate(CATALOG_ROUTE + `/${item.id}` + '/products')
    } else {
      dispatch(setProducts([]))
      navigate(CATALOG_ROUTE + `/${item.id}`)
    }
    handleCloseSubMenu()
    setMenuActive(false)
  }

  return (
    <div
      className='w-full py-1 px-4'
      onMouseEnter={() => {
        if (window.innerWidth >= 768) {
          handleOpenSubMenu(item.children)
        }
      }}
      onMouseLeave={() => {
        if (window.innerWidth >= 768) {
          handleCloseSubMenu()
        }
      }}
    >
      <div
        className='flex items-center p-2 rounded-md cursor-pointer
      hover:bg-gray-200 hover:text-firmBlue transition duration-200'
        onClick={handleClickOnCategories}
      >
        <div className='block w-10 mr-1'>
          <img className='w-full h-full' src={item.img || altLogo} alt='' />
        </div>
        {item.name}
      </div>
    </div>
  )
}

export default CategoriesItem
