import { Routes, Route } from 'react-router-dom'
import { publicRoutes, privateRoutes } from 'routs/routes'
import MainPage from 'pages/mainpage/Mainpage'
import { useSelector } from 'react-redux'
import { ADMIN_ROUTE, CATALOG_ROUTE } from 'constants/consts'
import AdminPanel from 'pages/adminPanel/adminPanel'

function AppRouter() {
  // const {userAuth} = useSelector(state => state.toolkit)
  // const [user] = useAuthState(userAuth)
  const { user } = useSelector(state => state.toolkit)
  const userAdmin = user?.admin

  return user ? (
    <Routes>
      {privateRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={Component} />
      ))}

      {userAdmin ? <Route path={ADMIN_ROUTE} element={<AdminPanel />} /> : null}

      <Route path={CATALOG_ROUTE + '/:id'} element={null} />
      <Route path='*' element={<MainPage />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={Component} />
      ))}

      <Route path={CATALOG_ROUTE + '/:id'} element={null} />
      <Route path='*' element={<MainPage />} />
    </Routes>
  )
}

export default AppRouter
