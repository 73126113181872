import ButtonBack from 'components/ButtonBack'
import Loader from 'components/Loader'
import CategoryItem from 'pages/catalog/CategoryItem'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Categories = ({ loading }) => {
  const { categories } = useSelector(state => state.toolkit)
  const { id } = useParams()

  return (
    <>
      {id && <ButtonBack />}
      <div className='w-full text-center text-3xl mt-4 font-medium'>Оберіть категорію</div>
      <div className='p-8 flex flex-wrap justify-start'>
        {loading ? (
          <div className='w-full flex items-center justify-center'>
            <Loader />
          </div>
        ) : categories.length > 0 ? (
          categories.map((item, index) => <CategoryItem category={item} key={index} />)
        ) : (
          <div className='w-full flex items-center justify-center'>
            {' '}
            <div>Категорії відсутні</div>
          </div>
        )}
      </div>
    </>
  )
}
export default Categories
