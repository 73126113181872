import ActionModal from 'components/navbar/userActions/ActionModal'
import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import ProductItem from 'components/navbar/userActions/ProductItem'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddBasket, RemoveBasket, RemoveFavorit, setBasketActive } from 'redux/toolkitSlice'
import { ReactComponent as Check } from 'img/icons/check-circle.svg'
import { ReactComponent as ShoppingCart } from 'img/icons/shopping-cart.svg'

const Favorite = ({ setFavoriteActive }) => {
  const dispatch = useDispatch()

  const { favorite, basket } = useSelector(state => state.toolkit)

  return (
    <ActionModal closeAction={setFavoriteActive}>
      <div className='max-h-[70vh] overflow-auto'>
        {favorite.length > 0 ? (
          favorite.map(item => (
            <ProductItem
              key={item.id}
              product={item}
              actionButtons={
                <div className='flex flex-col items-center justify-center gap-1'>
                  {basket.find(product => product.id === item.id) ? (
                    <ButtonActionModal
                      icon={<Check className='w-6 h-4 fill-emerald-500' />}
                      title={'В корзині'}
                      action={() => dispatch(RemoveBasket(item))}
                    />
                  ) : (
                    <ButtonActionModal
                      icon={<ShoppingCart className='w-6 h-4' />}
                      title={'В корзину'}
                      action={() =>
                        dispatch(
                          AddBasket({
                            product: item,
                            quantity: 1
                          })
                        )
                      }
                    />
                  )}
                  <ButtonActionModal title={'Видалити'} action={() => dispatch(RemoveFavorit(item))} />
                </div>
              }
            />
          ))
        ) : (
          <div className='text-center text-2xl'>Список обраного порожній</div>
        )}
      </div>
    </ActionModal>
  )
}

export default Favorite
