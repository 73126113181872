import { DOLLAR_EXCHANGE_RATE } from 'constants/consts'
import { useSelector } from 'react-redux'

const TotalSumProductPrice = ({ amountUSD, amountUAH }) => {
  const { user } = useSelector(state => state.toolkit)

  return user && user.opt ? (
    <div>
      <div>{amountUSD.toFixed(2)} $</div>
      <div>{amountUAH.toFixed(2)} грн</div>
    </div>
  ) : (
    <div>{Math.ceil((amountUSD * DOLLAR_EXCHANGE_RATE + amountUAH) * 1.3)} грн</div>
  )
}

export default TotalSumProductPrice
