import ButtonBack from 'components/ButtonBack'
import Loader from 'components/Loader'
import ProductItem from 'pages/catalog/ProductItem/ProductItem'
import { useSelector } from 'react-redux'

const Products = ({ loading }) => {
  const { categoryName, products } = useSelector(state => state.toolkit)

  return (
    <div className='h-fit min-h-screen'>
      <div className='relative top-20 w-full'>
        <ButtonBack />
        <div className='w-full text-center text-3xl mt-4 font-medium'>{categoryName}</div>
        <div className='p-8 flex flex-wrap justify-start'>
          {loading ? (
            <div className='w-full flex items-center justify-center'>
              <Loader />
            </div>
          ) : products.length > 0 ? (
            products.map((item, index) => <ProductItem key={index} product={item} />)
          ) : (
            <div className='w-full flex items-center justify-center'>
              {' '}
              <div>Товари відсутні</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Products
