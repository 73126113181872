import InputPhoneNumber from 'components/InputPhoneNumber'
import ActionModal from 'components/navbar/userActions/ActionModal'
import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import TotalSumProductPrice from 'components/TotalSumProductPrice'
import { DOLLAR_EXCHANGE_RATE, MAIN_ROUTE } from 'constants/consts'
import { createOrder } from 'http/order'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RemoveBasketAll } from 'redux/toolkitSlice'

const ConfirmOrder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState({
    firstName: null,
    secondName: null,
    phone: null,
    city: null,
    opt: false
  })
  const [openModal, setOpenModal] = useState(false)
  const { user, basket } = useSelector(state => state.toolkit)

  useEffect(() => {
    if (user) {
      setContacts({
        firstName: user.firstName || '',
        secondName: user.secondName || '',
        phone: user.telef || '',
        city: user.city || '',
        opt: user.opt || ''
      })
    }
  }, [user])

  function productsByUser(productInBasket) {
    if (user && user.opt) {
      return productInBasket
    } else {
      return convertProductPriseForRetail(productInBasket)
    }
  }

  function convertProductPriseForRetail(productInBasket) {
    return productInBasket.map(item => ({ ...item, priceUSD: 0, priceUAH: priseInUAH(item.priceUAH, item.priceUSD) }))
  }

  function priseInUAH(priceUAH, priceUSD) {
    return priceUSD ? Math.ceil(priceUSD * DOLLAR_EXCHANGE_RATE * 1.3) : Math.ceil(priceUAH * 1.3)
  }

  const handleInputChange = e => {
    const { name, value } = e.target

    setContacts(prevContacts => ({
      ...prevContacts,
      [name]: value
    }))
  }

  async function sendOrder(data) {
    try {
      await createOrder(data)
      dispatch(RemoveBasketAll())
      setOpenModal(true)
    } catch (e) {
      alert('Виникла непердбачувана помилка. Зателефонуйте у відділ продажу')
    }
  }

  function handleSubmit() {
    if (contacts.firstName && contacts.secondName && contacts.phone && contacts.city) {
      const data = {
        contacts: contacts,
        products: productsByUser(basket)
      }
      sendOrder(data)
    } else {
      alert('Всі поля мають бути заповнені!')
    }
  }

  function handleCloseModal() {
    setOpenModal(false)
    navigate(MAIN_ROUTE)
  }

  return (
    <div className='h-fit min-h-screen'>
      <div className='relative top-20 w-full flex justify-center items-start flex-col p-4'>
        <div className='flex flex-col gap-2 w-full sm:w-1/3'>
          <p className='text-xl'>Введіть контактні данні</p>
          <input
            type='text'
            name='firstName'
            className='p-2 rounded'
            value={contacts.firstName}
            onChange={handleInputChange}
            placeholder="Ім'я"
          />
          <input
            type='text'
            name='secondName'
            className='p-2 rounded'
            value={contacts.secondName}
            onChange={handleInputChange}
            placeholder='Призвіще'
          />
          {user && (
            <>
              <p>Ваш номер телефону: {user.telef}</p>
              <p>При необхідності додайте додатковий номер</p>
            </>
          )}
          <InputPhoneNumber action={handleInputChange} defaultValue={contacts.phone} />
          <input
            type='text'
            name='city'
            className='p-2 rounded'
            value={contacts.city}
            onChange={handleInputChange}
            placeholder='Місто'
          />
        </div>
        <div className='my-5 text-2xl '>
          <p className=''>Ваше замовлення на сумму:</p>
          <TotalSumProductPrice
            amountUSD={basket.reduce((sum, product) => sum + product.amount.amountUSD, 0)}
            amountUAH={basket.reduce((sum, product) => sum + product.amount.amountUAH, 0)}
          />
          <p className='text-sm'>Деталі замовлення можна переглянути та відредагувати в корзині</p>
        </div>
        <ButtonActionModal action={handleSubmit} title={'Підтвердити замовлення'} disabled={false} />
      </div>
      {openModal && (
        <div>
          <ActionModal
            closeAction={handleCloseModal}
            children={
              <div className='text-center'>
                <p className='text-2xl'>Дякуємо за замовлення!</p>
                <p className='text-xl'>Найближчим часом менеджер з вами зв'яжеться</p>
              </div>
            }
          />
        </div>
      )}
    </div>
  )
}

export default ConfirmOrder
