import InputMask from 'react-input-mask'

const InputPhoneNumber = ({ action }) => {
  return (
    <InputMask mask='+38(099)9999999' placeholder='+38(0)' onChange={action}>
      {inputProps => <input name='phone' className='p-2 sm:w-1/2 w-11/12' {...inputProps} type='text' />}
    </InputMask>
  )
}

export default InputPhoneNumber
