import InputPhoneNumber from 'components/InputPhoneNumber';
import React, { useEffect, useRef, useState } from 'react';
import "./registration.css";
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTE } from '../../constants/consts';
import { createUser, checkUserExists, fetchUser } from '../../http/userApi';
import { ReCAPTCHA } from "react-google-recaptcha";


function Registration() {
  const navigate = useNavigate();

  const backToHome = () => {
    navigate(MAIN_ROUTE);
  }

  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [city, setCity] = useState('');
  const [telef, setTelef] = useState('');
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [usersTelef, setusersTelef] = useState([]);

  const fetchUsers = async () => {
    const data = await fetchUser();
    const Arr = []
    data.map((item) => Arr.push(item.telef))
    setusersTelef(Arr);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const recaptchaRef = useRef();

  const addUser = async(e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    let obj = {
      firstName: firstName,
      secondName: secondName,
      city: city,
      telef: telef,
      password: password,
    }

    if (telef.length < 18) {
      alert("Номер телефону введено не коректно");
    } else{
      if (password === password1) {

        if (usersTelef.includes(telef)) {
          alert("Користувач з таким номером телефону вже існує")
        } else {
          createUser(obj)
            .then(data => {
              setShowModal(true);
            })
            .catch(() => {
              alert("Заповніть всі поля");
            });
        }
      } else {
        alert("Пароль записано не вірно");
      }
    }}

  const formatTelef = (value) => {
    let digitsOnly = value.replace(/[^\d]/g, ''); // Видаляємо всі нечислові символи
    const maxLength = 12; // Максимальна довжина номера телефону

    let formattedTelef = '+38 ';

    if (digitsOnly.length > maxLength) {
      digitsOnly = digitsOnly.slice(0, maxLength);
    }

    if (digitsOnly.length >= 3) {
      formattedTelef += `(${digitsOnly.slice(2, 5)}) `;
    }

    if (digitsOnly.length >= 6) {
      formattedTelef += `${digitsOnly.slice(5, 8)}-`;
    }

    formattedTelef += digitsOnly.slice(8);

    return formattedTelef;
  }

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedTelef = formatTelef(value);
    setTelef(formattedTelef);
  }

  return (
    <div className="Registration">
      <div className='Registration__container'>
        {showModal && (
          <div className="modal">
            <h3>Вітаю!</h3>
            <p>Ви успішно зареєстровані</p>
            <p>Ваш логін: {telef}</p>
            <p>Ваш пароль: {password}</p>
            <button onClick={() => {
              setShowModal(false);
              backToHome();
            }}>Ok</button>
          </div>
        )}
        Ім'я <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" />
        Призвіще <input value={secondName} onChange={e => setSecondName(e.target.value)} type="text" />
        Місто <input value={city} onChange={e => setCity(e.target.value)} type="text" />
        Номер телефону <InputPhoneNumber action={handlePhoneChange} />
        Пароль<input value={password1} onChange={e => setPassword1(e.target.value)} type="text" />
        Повторіть Ваш пароль <input value={password} onChange={e => setPassword(e.target.value)} type="text" />
        <ReCAPTCHA ref={recaptchaRef} sitekey="6LcfJY8mAAAAAIznqTab5lOJXI_qQQJffn3vbHH6" />
        <input className='Registration__button' type="submit" value="Зареєструватись" onClick={addUser} />
      </div>
    </div>
  );
}

export default Registration;
